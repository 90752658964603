<template>
  <b-img class="logo-big" fluid :src="require('@/assets/images/logo/logo.svg')" alt="Login V2" />
</template>

<script>
/* eslint-disable global-require */
import {
  BImg
} from 'bootstrap-vue'

export default {
  components: {
    BImg,
  },
}
</script>